@import url("https://fonts.googleapis.com/css2?family=La+Belle+Aurore&family=Syne:wght@400;500;600;700&display=swap");

/*VARIABLES*/
:root,
[data-theme="dark"] {
  /*COLORS*/
  --body-color: #131313 !important;
  --text-color: rgb(202, 202, 202);
  --first-color: #002984;
  --title-color: #fff;
  --container-color: rgb(31, 31, 31);
  --socials-color: #3f51b5;
  --socials-color-hover: #b5a33f;
  --toggle-color: #002984;
  --sidebar-color: #8f8f8fd8;
  --sidebar-color-hover: #8f8f8f;
  --card-gradient: 90deg, rgba(82, 82, 82, 0.5) 0%, rgba(54, 54, 54, 0.5) 100%;

  /*FONTS*/
  --body-font: "Syne", sans-serif !important;
  --h1-font-size: 2rem !important;
  --h2-font-size: 1.5rem !important;
  --h3-font-size: 1.25rem !important;
  --normal-font-size: 1rem !important;
  --small-font-size: 0.875rem !important;
  --smaller-font-size: 0.75rem !important;

  /*FONT WEIGHTS*/
  --font-medium: 500;
  --font-semi-bold: 600;
  --font-bold: 700;

  /*BOX SHADOWS*/
  --shadow: 0px 5px 20px 0px rgba(97, 97, 97, 0.1);

  /*BORDERS*/
  --border-radius: 20px;

  /*FILTERS*/
  --filter: invert(1);
}

[data-theme="light"] {
  /*COLORS*/
  --body-color: #fff !important;
  --text-color: hsl(243, 9%, 44%);
  --first-color: #002984;
  --title-color: #131313;
  --container-color: #f5f5f5;
  --socials-color: #3f51b5;
  --socials-color-hover: #ffc400;
  --toggle-color: #ffc400;
  --sidebar-color: #bebebed0;
  --sidebar-color-hover: ##ffc400;
  --shadow: 0px 5px 20px 0px rgba(54, 54, 54, 0.1);
  --card-gradient: 90deg, rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.5) 100%;
  --filter: invert(0);
}

/*RESPONSIVE*/
@media screen and (max-width: 1024px) {
  :root {
    --h1-font-size: 1.75rem;
    --h2-font-size: 1.25rem;
    --h3-font-size: 1rem;
    --normal-font-size: 0.935rem;
    --small-font-size: 0.813rem;
    --smaller-font-size: 0.75rem;
  }
}

/*BASE STYLES*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body,
button,
input,
textarea {
  font-family: var(--body-font);
  font-weight: var(--font-medium);
}

.app {
  background-color: var(--body-color) !important;
  color: var(--text-color);
}

body {
  line-height: 1.7;
  overflow-x: hidden;
}

h1,
h2,
h3 {
  color: var(--title-color);
  font-weight: var(--font-semi-bold);
  line-height: 1.2;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
  border: none;
  outline: none;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

/*CSS Classes*/
.container {
  max-width: 1080px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
}

.grid {
  display: grid;
}

.section {
  padding-top: 5rem;
  padding-bottom: 2rem;
}

.section__title {
  font-size: var(--h1-font-size);
  margin-left: 0.875rem;
  position: relative;
  font-weight: var(--font-bold);
  /*margin-bottom: 3.75rem;*/
}

.tags {
  color: var(--sidebar-color);
  opacity: 0.6;
  font-family: "La Belle Aurore", cursive;
  font-size: 1.5rem;
  position: relative;
}

.bottom-tags {
  margin-top: 0.4rem;
  margin-bottom: 3.75rem;
}

.section__title::before {
  content: "";
  height: 2.25rem;
  width: 2.25rem;
  position: absolute;
  left: -0.875rem;
  top: -0.875rem;
}

.btn {
  padding: 0.75rem 2rem;
  line-height: 1;
  border-radius: 1.875rem;
  box-shadow: 0 0 1px rgb(0 0 0 / 10%);
  border: 1px solid transparent;
  color: rgb(255, 255, 255);
  display: inline-block;
  background-color: var(--first-color);
  font-weight: var(--font-bold);
  transition: 0.3s ease-in-out;
}

.theme__toggle {
  position: fixed;
  top: 1rem;
  right: 1rem;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: var(--toggle-color);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 999;
}

.loader {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.background--custom {
  background-color: #ffffff00;
  width: 100vw;
  height: 100vh;
  position: absolute;
  overflow: hidden;
  z-index: 0;
  top: 0;
  left: 0;
}

#gradient-canvas {
  width: 100%;
  height: 100%;
  --gradient-color-1: #c3e4ff;
  --gradient-color-2: #6ec3f4;
  --gradient-color-3: #eae2ff;
  --gradient-color-4: #b9beff;
}

/* @keyframes button-push{
    /* bounce to bottom 
    0%{
        transform: translateY(0);
    }
    50%{
        transform: translateY(2px);
    }
    100%{
        transform: translateY(4);
    }
} */

.btn:hover {
  animation: button-push 0.5s;
  background-color: #757de8;
  transform: translateY(2px);
  transition: 0.3s ease-in-out;
}
