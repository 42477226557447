@import url(https://fonts.googleapis.com/css2?family=La+Belle+Aurore&family=Syne:wght@400;500;600;700&display=swap);
/*VARIABLES*/
:root,
[data-theme="dark"] {
  /*COLORS*/
  --body-color: #131313 !important;
  --text-color: rgb(202, 202, 202);
  --first-color: #002984;
  --title-color: #fff;
  --container-color: rgb(31, 31, 31);
  --socials-color: #3f51b5;
  --socials-color-hover: #b5a33f;
  --toggle-color: #002984;
  --sidebar-color: #8f8f8fd8;
  --sidebar-color-hover: #8f8f8f;
  --card-gradient: 90deg, rgba(82, 82, 82, 0.5) 0%, rgba(54, 54, 54, 0.5) 100%;

  /*FONTS*/
  --body-font: "Syne", sans-serif !important;
  --h1-font-size: 2rem !important;
  --h2-font-size: 1.5rem !important;
  --h3-font-size: 1.25rem !important;
  --normal-font-size: 1rem !important;
  --small-font-size: 0.875rem !important;
  --smaller-font-size: 0.75rem !important;

  /*FONT WEIGHTS*/
  --font-medium: 500;
  --font-semi-bold: 600;
  --font-bold: 700;

  /*BOX SHADOWS*/
  --shadow: 0px 5px 20px 0px rgba(97, 97, 97, 0.1);

  /*BORDERS*/
  --border-radius: 20px;

  /*FILTERS*/
  --filter: invert(1);
}

[data-theme="light"] {
  /*COLORS*/
  --body-color: #fff !important;
  --text-color: hsl(243, 9%, 44%);
  --first-color: #002984;
  --title-color: #131313;
  --container-color: #f5f5f5;
  --socials-color: #3f51b5;
  --socials-color-hover: #ffc400;
  --toggle-color: #ffc400;
  --sidebar-color: #bebebed0;
  --sidebar-color-hover: ##ffc400;
  --shadow: 0px 5px 20px 0px rgba(54, 54, 54, 0.1);
  --card-gradient: 90deg, rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.5) 100%;
  --filter: invert(0);
}

/*RESPONSIVE*/
@media screen and (max-width: 1024px) {
  :root {
    --h1-font-size: 1.75rem;
    --h2-font-size: 1.25rem;
    --h3-font-size: 1rem;
    --normal-font-size: 0.935rem;
    --small-font-size: 0.813rem;
    --smaller-font-size: 0.75rem;
  }
}

/*BASE STYLES*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body,
button,
input,
textarea {
  font-family: var(--body-font);
  font-weight: var(--font-medium);
}

.app {
  background-color: var(--body-color) !important;
  color: var(--text-color);
}

body {
  line-height: 1.7;
  overflow-x: hidden;
}

h1,
h2,
h3 {
  color: var(--title-color);
  font-weight: var(--font-semi-bold);
  line-height: 1.2;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
  border: none;
  outline: none;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

/*CSS Classes*/
.container {
  max-width: 1080px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
}

.grid {
  display: grid;
}

.section {
  padding-top: 5rem;
  padding-bottom: 2rem;
}

.section__title {
  font-size: var(--h1-font-size);
  margin-left: 0.875rem;
  position: relative;
  font-weight: var(--font-bold);
  /*margin-bottom: 3.75rem;*/
}

.tags {
  color: var(--sidebar-color);
  opacity: 0.6;
  font-family: "La Belle Aurore", cursive;
  font-size: 1.5rem;
  position: relative;
}

.bottom-tags {
  margin-top: 0.4rem;
  margin-bottom: 3.75rem;
}

.section__title::before {
  content: "";
  height: 2.25rem;
  width: 2.25rem;
  position: absolute;
  left: -0.875rem;
  top: -0.875rem;
}

.btn {
  padding: 0.75rem 2rem;
  line-height: 1;
  border-radius: 1.875rem;
  box-shadow: 0 0 1px rgb(0 0 0 / 10%);
  border: 1px solid transparent;
  color: rgb(255, 255, 255);
  display: inline-block;
  background-color: var(--first-color);
  font-weight: var(--font-bold);
  transition: 0.3s ease-in-out;
}

.theme__toggle {
  position: fixed;
  top: 1rem;
  right: 1rem;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: var(--toggle-color);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 999;
}

.loader {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.background--custom {
  background-color: #ffffff00;
  width: 100vw;
  height: 100vh;
  position: absolute;
  overflow: hidden;
  z-index: 0;
  top: 0;
  left: 0;
}

#gradient-canvas {
  width: 100%;
  height: 100%;
  --gradient-color-1: #c3e4ff;
  --gradient-color-2: #6ec3f4;
  --gradient-color-3: #eae2ff;
  --gradient-color-4: #b9beff;
}

/* @keyframes button-push{
    /* bounce to bottom 
    0%{
        transform: translateY(0);
    }
    50%{
        transform: translateY(2px);
    }
    100%{
        transform: translateY(4);
    }
} */

.btn:hover {
  -webkit-animation: button-push 0.5s;
          animation: button-push 0.5s;
  background-color: #757de8;
  -webkit-transform: translateY(2px);
          transform: translateY(2px);
  transition: 0.3s ease-in-out;
}

.main {
  margin-left: 110px;
}

@media screen and (max-width: 1024px) {
  .main {
    margin-left: 0;
  }
}

.Window3d {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Window3dScene {
  width: 100%;
  height: 100%;
}

.NotFound {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.NotFound-asset{
  width: 42%;
  height: 100%;
}

.NotFound-content {

  width: 40%;
  height: 100%;
  position: realtive;
  font-weight: bolder;
  display: flex;  
  flex-direction: column;
  align-items:center;
  justify-content: center;
}

.NotFound-text {
  font-size: 50px;
  font-weight: bolder;
  -webkit-animation: colorDeg 10s infinite linear;
          animation: colorDeg 10s infinite linear;
  
}


@-webkit-keyframes colorDeg {
  0%{
    color: #fff;
    text-shadow:
    0 0 7px #fff,
    0 0 10px #fff,
    0 0 21px #fff,
    0 0 42px rgb(30, 206, 233),
    0 0 82px rgb(30, 206, 233),
    0 0 92px rgb(30, 206, 233),
    0 0 102px rgb(30, 206, 233),
    0 0 151px rgb(30, 206, 233);
  }
  25%{
    color: #fff;
    text-shadow:
    0 0 7px #fff,
    0 0 10px #fff,
    0 0 21px #fff,
    0 0 42px rgb(244, 32, 226),
    0 0 82px rgb(244, 32, 226),
    0 0 92px rgb(244, 32, 226),
    0 0 102px rgb(244, 32, 226),
    0 0 151px rgb(244, 32, 226);
  }
  75%{
    color:#fff;
    text-shadow:
    0 0 7px #fff,
    0 0 10px #fff,
    0 0 21px #fff,
    0 0 42px rgb(251, 244, 29),
    0 0 82px rgb(251, 244, 29),
    0 0 92px rgb(251, 244, 29),
    0 0 102px rgb(251, 244, 29),
    0 0 151px rgb(251, 244, 29);
  }
  100%{
    color: #fff;
    text-shadow:
    0 0 7px #fff,
    0 0 10px #fff,
    0 0 21px #fff,
    0 0 42px rgb(30, 206, 233),
    0 0 82px rgb(30, 206, 233),
    0 0 92px rgb(30, 206, 233),
    0 0 102px rgb(30, 206, 233),
    0 0 151px rgb(30, 206, 233);
  }
}


@keyframes colorDeg {
  0%{
    color: #fff;
    text-shadow:
    0 0 7px #fff,
    0 0 10px #fff,
    0 0 21px #fff,
    0 0 42px rgb(30, 206, 233),
    0 0 82px rgb(30, 206, 233),
    0 0 92px rgb(30, 206, 233),
    0 0 102px rgb(30, 206, 233),
    0 0 151px rgb(30, 206, 233);
  }
  25%{
    color: #fff;
    text-shadow:
    0 0 7px #fff,
    0 0 10px #fff,
    0 0 21px #fff,
    0 0 42px rgb(244, 32, 226),
    0 0 82px rgb(244, 32, 226),
    0 0 92px rgb(244, 32, 226),
    0 0 102px rgb(244, 32, 226),
    0 0 151px rgb(244, 32, 226);
  }
  75%{
    color:#fff;
    text-shadow:
    0 0 7px #fff,
    0 0 10px #fff,
    0 0 21px #fff,
    0 0 42px rgb(251, 244, 29),
    0 0 82px rgb(251, 244, 29),
    0 0 92px rgb(251, 244, 29),
    0 0 102px rgb(251, 244, 29),
    0 0 151px rgb(251, 244, 29);
  }
  100%{
    color: #fff;
    text-shadow:
    0 0 7px #fff,
    0 0 10px #fff,
    0 0 21px #fff,
    0 0 42px rgb(30, 206, 233),
    0 0 82px rgb(30, 206, 233),
    0 0 92px rgb(30, 206, 233),
    0 0 102px rgb(30, 206, 233),
    0 0 151px rgb(30, 206, 233);
  }
}
.aside {
  position: fixed;
  top: 0;
  left: 0;
  width: 300px;
  height: 100%;
  background: var(--body-color);
  border-right: 1px solid var(--sidebar-color);
  padding: 2.5rem;
  width: 110px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 999;
  transition: 0.3s;
}

.nav__logo{
    -webkit-filter: var(--filter);
            filter: var(--filter);
}

.nav__list {
  display: flex;
  flex-direction: column;
  grid-row-gap: 1rem;
  row-gap: 1rem;
}

.nav__link {
  font-size: 1.5rem;
  color: var(--title-color);
  font-weight: var(--font-bold);
  transition: 0.3s;
}

.nav__link:hover {
  color: var(--sidebar-color-hover);
}

.copyright {
  color: hsl(0, 0%, 68%);
  font-size: var(--small-font-size);
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
  -webkit-writing-mode: vertical-rl;
          writing-mode: vertical-rl;
}

.nav__toggle {
  position: fixed;
  top: 1.25rem;
  left: 1.875rem;
  cursor: pointer;
  height: 40px;
  width: 45px;
  background-color: var(--body-color);
  border: 1px solid var(--sidebar-color);
  display: none;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  z-index: 999;
  transition: 0.3s;
}

@media screen and (max-width: 1024px) {
  .aside {
    left: -110px;
  }

  .nav__toggle {
    display: flex;
  }

  .nav__toggle-open {
    left: 140px;
  }

  .show-menu {
    left: 0;
  }
}

.home {
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
}

.intro {
  max-width: 540px;
  /* background-color: blueviolet; */
  text-align: center;
  z-index: 100;
}

.home__img {
  height: 200px;
  /* margin-bottom: 1.5rem; */
}

.home__name {
  font-size: var(--h1-font-size);
  font-weight: var(--font-bold);
  margin-bottom: 0.5rem;
}

.home__name__sub {
  font-size: var(--h3-font-size);
  font-weight: var(--font-medium);
  margin-bottom: 0.1rem;
  color: var(--text-color);
}

.home__socials {
  display: flex;
  justify-content: center;
  grid-column-gap: 1.8rem;
  -webkit-column-gap: 1.8rem;
          column-gap: 1.8rem;
  margin: 1.5rem 0;
}

.home__socials-link {
  color: var(--socials-color);
  font-size: 1.4rem;
  transition: 0.3s;
}

.home__socials-link:hover {
  color: var(--socials-color-hover);
  cursor: pointer;
}

.scroll_down {
  position: absolute;
  bottom: 2.5rem;
  left: 0;
  width: 100%;
}

.home__scroll-name {
  font-size: var(--small-font-size);
  color: var(--text-color);
}

.mouse {
  border: 2px solid var(--text-color);
  display: block;
  height: 1.6rem;
  width: 1.25rem;
  margin: auto;
  margin-top: 0.75rem;
  border-radius: 1rem;
  position: relative;
}

@-webkit-keyframes ani-mouse {
  0% {
    opacity: 1;
    top: 15%;
  }
  70% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
    top: 50%;
  }
}

@keyframes ani-mouse {
  0% {
    opacity: 1;
    top: 15%;
  }
  70% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
    top: 50%;
  }
}

.mouse__wheel {
  background-color: var(--title-color);
  border-radius: 100%;
  height: 0.25rem;
  width: 0.25rem;
  position: absolute;
  top: 0.5rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  -webkit-animation: ani-mouse 1.5s infinite;
          animation: ani-mouse 1.5s infinite;
}

.wrapper {
  overflow: hidden;
}

.shapes {
  position: absolute;
  top: 5%;
  left: 5%;
  height: 90%;
  width: 90%;
  z-index: 0;
}

.shape {
  position: absolute;
}

.s1 {
  top: 50%;
  left: 10%;
}

.s2 {
  top: 10%;
  left: 2%;
}

.s3 {
  top: 85%;
  right: 2%;
}

.s4 {
  bottom: 90%;
  right: 2%;
}

.s5 {
  top: 25%;
  left: 20%;
}

.s6 {
  top: 20%;
  left: 70%;
}

.s7 {
  top: 10%;
  left: 50%;
}

.s8 {
  top: 30%;
  left: 90%;
}

.s9 {
  top: 90%;
  left: 1%;
}

.s10 {
  top: 50%;
  left: 80%;
}

.s11 {
  top: 70%;
  left: 20%;
}

@media screen and (max-width: 1024px) {
  .home__socials-link {
    font-size: 1.125rem;
  }
}

.about__container {
  grid-template-columns: 3fr 9fr;
  grid-column-gap: 1.875rem;
  -webkit-column-gap: 1.875rem;
          column-gap: 1.875rem;
}

.about__data {
  padding: 1.875rem;
  background-color: var(--container-color);
  box-shadow: var(--shadow);
  border-radius: var(--border-radius);
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 1.875rem;
  -webkit-column-gap: 1.875rem;
          column-gap: 1.875rem;
  align-items: flex-start;
  position: relative;
}

.about__data::before {
  content: "";
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 15px solid var(--container-color);
  position: absolute;
  left: -0.93rem;
  top: 20%;
}

.about__description {
  margin-bottom: 1.875rem;
  text-align: justify;
}

.about__skils {
  grid-row-gap: 1.25rem;
  row-gap: 1.25rem;
}

.skills__titles {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.skills__name {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
}

.skills__number {
  line-height: 1.2;
}

.skills__bar,
.skills__percentage {
  height: 7px;
  border-radius: 0.25rem;
}

.skills__bar {
  background-color: var(--sidebar-color);
}

.skills__percentage {
  display: block;
}

.bar {
  background-color: var(--socials-color-hover);
}

@media screen and (max-width: 1024px) {
  .about__container {
    grid-template-columns: 720px;
    justify-content: center;
    grid-row-gap: 1.875rem;
    row-gap: 1.875rem;
  }

  .about__data::before {
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid var(--container-color);
    left: 49%;
    top: -20px;
  }

  .about__img {
    justify-content: center;
  }
}

@media screen and (max-width: 768px) {
  .about__container {
    grid-template-columns: 350px;
  }

  .about__data::before {
    left: 47%;
  }

  .about__data {
    grid-template-columns: 1fr;
    grid-row-gap: 1.875rem;
    row-gap: 1.875rem;
  }
}

@media screen and (max-width: 576px) {
  .about__container {
    grid-template-columns: 320px;
  }
}

@media screen and (max-width: 350px) {
  .about__container {
    grid-template-columns: 1fr;
  }
}

.resume__container {
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 1.875rem;
  -webkit-column-gap: 1.875rem;
          column-gap: 1.875rem;
}

.timeline {
  background-color: var(--container-color);
  padding: 1.875rem;
  border-radius: var(--border-radius);
  position: relative;
  box-shadow: var(--shadow);
}

.timeline__item {
  position: relative;
  padding-left: 3.125rem;
  padding-bottom: 3.125rem;
}

.timeline__item:last-child {
  padding-bottom: 0;
}

.timeline__item::before {
  content: "";
  width: 1px;
  height: 100%;
  background-color: var(--socials-color);
  position: absolute;
  left: 0.25rem;
  top: 0;
}

.timeline .icon-briefcase,
.timeline .icon-graduation {
  position: absolute;
  left: -0.4375rem;
  top: 0;
  font-size: var(--h2-font-size);
  color: var(--socials-color);
  background-color: var(--container-color);
  padding: 0.4375rem 0;
}

.timeline__date {
  font-size: var(--small-font-size);
}

.timeline__title {
  font-size: var(--h3-font-size);
  font-weight: var(--font-bold);
  margin-bottom: 0.5rem 0;
}

@media screen and (max-width: 1024px) {
  .resume__container {
    grid-template-columns: 450px;
    justify-content: center;
    grid-row-gap: 1.875rem;
    row-gap: 1.875rem;
  }

  .timeline__item::before {
    left: 2px;
  }
}

@media screen and (max-width: 576px) {
  .resume__container {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 350px) {
  .timeline__item {
    padding-left: 1.875rem;
    padding-bottom: 1.875rem;
  }

  .timeline__item::before {
    left: 0;
  }

  .timeline .icon-briefcase,
  .timeline .icon-graduation {
    left: -9px;
  }

  .timeline__text {
    text-align: justify;
  }
}

.gallery {
  padding: 10px;
  max-width: 1100px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-auto-rows: 250px;
  grid-auto-flow: dense;
  grid-gap: 4rem;
  transition: all 10s ease-in-out;
}

.v-stretch {
  grid-row: span 2;
}

.h-stretch {
  grid-column: span 2;
}

.big-stretch {
  grid-row: span 2;
  grid-column: span 2;
}

.one-stretch {
  grid-row: span 1;
  grid-column: span 1;
}

.gallery-card {
  display: flex;
  flex-wrap: wrap;
  transition: 0.3s;
}

.card-content {
  border-radius: 10px 10px 0 0;
  overflow-y: auto;
  -webkit-animation: fade-in 0.3s;
          animation: fade-in 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.card-title {
  display: block;
  font-size: var(--h2-font-size);
  font-weight: var(--font-bold);
  padding: 10px;
  color: var(--title-color);
  text-transform: capitalize;
}

.icon__container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-size: var(--h1-font-size);
  color: var(--text-color-hover);
}

.icon__container a {
  text-decoration: none;
  color: var(--text-color-hover);
  transition: 0.3s;
}

.card-title__date {
  display: flex;
  align-items: center;
  font-size: var(--small-font-size);
  color: var(--text-color);
  -webkit-filter: invert(0.6);
          filter: invert(0.6);
}

.card-title__date p {
  margin: 0 5px;
}

.icon__container a:hover{
  cursor: pointer;
  color: var(--text-color);
}

.card-technologies {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  margin: 10px;
}

.card-technologies__item {
  background-color: var(--container-color);
  min-height: 20px;
  min-width: 30px;
  padding: 2px 10px;
  border-radius: 20px;
  z-index: 1;
  margin: 2px 5px;
  display: flex;
  align-items: center;
}

.technologie-name {
  display: inline-block;
  padding: 5px;
}

.gallery-card:hover {
  cursor: pointer;
  box-shadow: var(--shadow);
  -webkit-transform: translate(10px, 0);
          transform: translate(10px, 0);
}

.show-more {
  transition: 0.3s;
  padding: 10px;
  background-color: var(--container-color);
  color: var(--text-color);
  border-radius: 0 0 10px 10px;
  width: 100%;
  display: flex;
  align-items: center;
}

.show-more i {
  margin-right: 5px;
}

.gallery-card:hover .show-more {
  -webkit-transform: translate(-20px, 0);
          transform: translate(-20px, 0);
}

.gallery-images {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gallery__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.PopUpScreen {
  height: 90vh;
  width: 90vw;
  position: -webkit-sticky;
  position: sticky;
  background-color: #fff;
  z-index: 10000;
}

.card-reveal {
  height: 100%;
  background-color: var(--container-color);
  padding: 24px;
  top: 100%;
  left: 0;
  -webkit-filter: invert(95%);
          filter: invert(95%);
  border-radius: 10px 10px 0 0;
  overflow-y: auto;
  -webkit-animation: fade-in 0.3s;
          animation: fade-in 0.3s;
  overflow-x: hidden;
}

.card-description {
  display: block;
  -webkit-margin-before: 1em;
          margin-block-start: 1em;
  -webkit-margin-after: 1em;
          margin-block-end: 1em;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
  font-size: var(--small-font-size);
  padding: 10px;
  color: var(--text-color);
  text-align: justify;
}

.project-title {
  display: flex;
  flex-direction: reverse;
  font-size: var(--h2-font-size);
  font-weight: var(--font-bold);
  padding: 10px;
  color: var(--title-color);
  text-transform: capitalize;

  background: linear-gradient(var(--card-gradient));

  align-items: flex-end;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media all and (min-width: 300px) {
  .v-stretch {
    grid-row: span 1;
  }
  .h-stretch {
    grid-column: span 1;
  }
  .big-stretch {
    grid-row: span 1;
    grid-column: span 1;
  }
}

@media all and (min-width: 900px) {
  .v-stretch {
    grid-row: span 2;
  }
  .h-stretch {
    grid-column: span 2;
  }
  .big-stretch {
    grid-row: span 2;
    grid-column: span 2;
  }
}

@media screen and (max-width: 900px) {
  .project-title {
    font-size: var(--small-font-size);
  }
  .card-technologies__item {
    min-width: 10px;
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 500px) {
  .project-title {
    font-size: 0.6rem;
  }
  .card-technologies__item {
    min-width: 5px;
    font-size: 0.7rem;
  }
}

.portfolio__container {
  min-height: 80vh;
  width: 100%;
}

.contact.section {
  padding-bottom: 6.25rem;
}

.contact__container {
  display: grid;
  grid-template-columns: 4fr 8fr;
  grid-column-gap: 1.875rem;
  -webkit-column-gap: 1.875rem;
          column-gap: 1.875rem;
}

.contact__title {
  font-size: var(--h3-font-size);
  margin-bottom: 0.5rem;
}

.contact__form-group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 1.5rem;
  -webkit-column-gap: 1.5rem;
          column-gap: 1.5rem;
}

.contact__form-div {
  position: relative;
  margin-bottom: 1.875rem;
  height: 3.75rem;
}

.contact__form-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: var(--shadow);
  background-color: var(--container-color);
  color: var(--text-color);
  border: none;
  outline: none;
  padding: 0.875rem 1.25rem;
  border-radius: var(--border-radius);
  z-index: 1;
}

.contact__form-area {
  height: 10.5rem;
}

.contact__form-area textarea {
  resize: none;
}

@media screen and (max-width: 1024px) {
  .contact__container {
    grid-template-columns: 300px 360px;
    justify-content: center;
  }

  .contact__form-group {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 768px) {
  .contact__container {
    grid-template-columns: 310px;
    grid-row-gap: 1.875rem;
    row-gap: 1.875rem;
  }

  .contact__info {
    text-align: center;
  }
}

