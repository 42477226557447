.Window3d {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Window3dScene {
  width: 100%;
  height: 100%;
}
